import { camelCase, snakeCase, startCase } from 'lodash'
import moment, { isMoment } from 'moment'

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'currency',
})

export const amountFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

export const addressFormatter = ({
  address1,
  address2,
  city,
  state_province,
  postal_code,
}) =>
  `${address1 || ''}${address2 ? ` ${address2}` : ''}, ${city || ''}, ${
    state_province || ''
  } ${postal_code || ''}`

const isObject = (value: any) => {
  try {
    Object.setPrototypeOf({}, value)
    return value !== null
  } catch (err) {
    return false
  }
}

const convertKeys = (converter: any, object: any): any => {
  if (!isObject(object)) {
    return object
  }

  if (Array.isArray(object)) {
    return object.map((o) => convertKeys(converter, o))
  }

  const converted = {}

  Object.keys(object).map((prop) => {
    converted[converter(prop)] = convertKeys(converter, object[prop])
    return prop
  })

  return converted
}

export const camelizeKeys = (object: any) => {
  return convertKeys(camelCase, object)
}

export const decamelizeKeys = (object: any) => {
  return convertKeys(snakeCase, object)
}

export const camelize = (string: string) => {
  return camelCase(string)
}

export const decamelize = (string: string) => {
  if (string.includes('_') || string.includes('.')) {
    return string
  }
  return snakeCase(string)
}

export const titleCase = (string: string) => {
  if (!string) {
    return ''
  }
  return startCase(camelCase(string))
}

/**
 * Parse float from provided string and returns string version of parse float value
 * It is useful to emulate input[type=number] field to support '1.' cases (when user trying to enter float value)
 */
export const parseStringFloat = (aString: string) => {
  let hasDotAtTheEnd = false
  let hasTenthZero = false

  if (aString === undefined || aString === null) {
    return
  }

  // Replace all the commas with dots to get correct float value
  const str = aString.toString().replace(/,/g, '.')

  // Do not remove dot while converting to float value when user enters '1.'
  if (str.match(/\.$/)) {
    hasDotAtTheEnd = true
  }

  // Allow for the tenth place to contain 0(Zero). Allows user to enter '1.0'
  if (str.match(/\.0$/)) {
    hasTenthZero = true
  }

  const floatValue = parseFloat(str)
  if (isNaN(floatValue)) {
    return '0'
  }

  return floatValue + (hasDotAtTheEnd ? '.' : '') + (hasTenthZero ? '.0' : '')
}

export const generateKeyValueArrayFromObject = (
  keysObj: any,
  valuesObj: any,
) => {
  const array: any[] = []
  Object.keys(keysObj).map((key: any) => {
    array.push({ key, value: valuesObj[key] })
  })
  return array
}

export const uuidGenerator = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: any) => {
    // tslint:disable-next-line: no-bitwise
    const r = (Math.random() * 16) | 0
    // tslint:disable-next-line: no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const addOrUpdateItems = (
  arr: any[],
  objs: any | any[],
  updateConditionFn?: any,
  itemIdentifier?: string,
) => {
  const newObjs: any[] = [].concat(objs)
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < newObjs.length; i = i + 1) {
    const newObj = newObjs[i]
    const existingObjIndex = arr.findIndex(
      (arrObj: any) =>
        arrObj[itemIdentifier || 'id'] === newObj[itemIdentifier || 'id'],
    )
    if (existingObjIndex === -1) {
      arr.push(newObj)
    } else {
      const existingObj = arr[existingObjIndex]
      if (
        (updateConditionFn && updateConditionFn(existingObj, newObj)) ||
        moment(newObj.updatedAt || newObj.updated_at) >
          moment(existingObj.updatedAt || existingObj.updated_at)
      ) {
        arr.splice(existingObjIndex, 1, newObj)
      }
    }
  }
  return arr
}

export const isBeforeDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false

  const aYear = a.year()
  const aMonth = a.month()

  const bYear = b.year()
  const bMonth = b.month()

  const isSameYear = aYear === bYear
  const isSameMonth = aMonth === bMonth

  if (isSameYear && isSameMonth) return a.date() < b.date()
  if (isSameYear) return aMonth < bMonth
  return aYear < bYear
}

export const isInclusivelyAfterDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false
  return !isBeforeDay(a, b)
}

export const isBetween = (day, a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b) || !moment.isMoment(day))
    return false
  return !isBeforeDay(day, a)
}

export function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date()
    && a.month() === b.month()
    && a.year() === b.year();
}

export default {
  addOrUpdateItems,
  camelizeKeys,
  decamelizeKeys,
  camelize,
  decamelize,
  currencyFormatter,
  titleCase,
  generateKeyValueArrayFromObject,
  uuidGenerator,
  isBeforeDay,
  isInclusivelyAfterDay,
  isBetween,
  isSameDay
}
