export const extractIncluded = (key: String, data: any) => {
  return data[`${key}`] ? data[`${key}`].data : []
}

export const createObjFromKVArray = (
  arr: any[],
  keyName: string,
  valueName: string,
) => {
  if (!arr) {
    return {}
  }
  return arr.reduce((acc, cur) => {
    acc[cur[keyName]] = cur[valueName]
    return acc
  }, {})
}

export const createKVArrayFromObj = (obj: object) => {
  if (!obj) {
    return []
  }
  const arr: object[] = []
  Object.keys(obj).map((key: string) => {
    arr.push({ key, value: obj[key] })
  })
  return arr
}

export const flattenApiResponse = ({
  external_identifiers,
  properties,
  ...rest
}: any) => {
  return {
    ...rest,
    ...createObjFromKVArray(external_identifiers, 'key', 'value'),
    ...createObjFromKVArray(properties, 'key', 'value'),
    properties: createObjFromKVArray(properties, 'key', 'value'),
  }
}

export const flattenApiResponseAll = (data: any) => data.map(flattenApiResponse)
