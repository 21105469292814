import { Alert, Button, Input, InputGroup, Textarea } from '@tkxs/cast-ui'
import { Field, Formik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import { useSession } from 'next-auth/react'
import { useCallback, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { Box, Flex } from 'rebass'
import useSWR, { mutate } from 'swr'
import * as Yup from 'yup'
import { SSelect } from '../../common/common'
import { TaskStatus, timeSuffixList } from '../../common/constants'
import { performRequest } from '../../pages/api'
import { extractIncluded } from '../../utils/api'
import { isAdmin } from '../../utils/auth'
import { isInclusivelyAfterDay } from '../../utils/converters'

export default function WorklogForm(props: any) {
  const { data: session, status } = useSession()

  const [successMessage, setSuccessMessage] = useState('')
  const [submitError, setSubmitError] = useState('')
  const [startDateFocus, setStartDateFocus] = useState(null)
  const [selectedTimesheetPeriod, setSelectedTimesheetPeriod] = useState<{
    day?: number
    week?: number
    month?: number
    year?: number
    employee_id?: any
  }>()
  const [selectedOrganization, setSelectedOrganization] = useState(
    session?.user['profile']?.organizationId,
  )
  const [selectedEmployee, setSelectedEmployee] = useState(
    session?.user['profile']?.id,
  )

  const filter = !isAdmin(session)
    ? `&filter[]=${session?.user['profile']?.employeeId}`
    : ''

  const { employee_id, workLogs, taskId, timesheetId, workLogId, taskName } =
    props

  const employeeId = selectedEmployee || employee_id

  const { data: orgData, error: orgError } = useSWR(
    !isAdmin(session) ? null : '/api/cache?cache=organizations',
  )

  const { data: timesheetsData, error: timesheetsError } = useSWR(
    !isAdmin(session) ? null : '/api/cache?cache=timesheets',
  )

  const { data: tasksData, error: tasksErrors } = useSWR(
    `/api/cache?cache=tasks${filter}`,
  )

  let organizations = orgData && orgData !== undefined ? orgData.data : null

  let organizationsOptions = []

  if (organizations) {
    organizations = organizations
      .map((org: any) => {
        const addresses = extractIncluded(
          'addresses',
          org.relationships ? org.relationships : [],
        )
        const workSites = extractIncluded(
          'workSites',
          org.relationships ? org.relationships : [],
        )
        const employees = extractIncluded(
          'employees',
          org.relationships ? org.relationships : [],
        )
        const departments = extractIncluded(
          'departments',
          org.relationships ? org.relationships : [],
        )

        if (
          org.relationships.departments.data.length > 0 &&
          org.relationships.employees.data.length > 0
        ) {
          organizationsOptions.push({
            value: org.id,
            label: org.attributes.name,
          })
          return {
            ...org,
            attributes: {
              ...org.attributes,
              addresses: addresses,
              worksites: workSites,
              employees: employees,
              departments: departments,
            },
          }
        }
      })
      .reverse()
    _.sortBy(organizationsOptions, ['label', 'value'])
  }

  const { data, error } = useSWR([
    selectedOrganization != null
      ? process.env.NEXT_PUBLIC_API_URL +
        `/organizations/${selectedOrganization}`
      : null,
    session?.user['accessToken'],
  ])

  let departments = []
  let employees = []

  if (data && data.included) {
    _.map(data.included, function (item) {
      if (item.type === 'departments') {
        departments.push({
          value: item.id,
          label: item.attributes.name,
        })
      }

      if (item.type === 'employees') {
        employees.push({
          value: item.id,
          label: item.attributes.name,
          email: item.attributes['workEmail'],
        })
      }
    })
  }

  let timesheets
  let timesheetOptions = []

  let tasks
  let taskOptions = []

  if (timesheetsData && timesheetsData.data && timesheetsData.data.length > 0) {
    // if (isAdmin(session)) {
    //     timesheets = _.filter(timesheetsData.data, function (o) {
    //         return o.attributes.sheetType === "report"
    //     })
    // } else {
    timesheets = _.filter(timesheetsData.data, function (o) {
      // if (employeeId) {
      //     return (
      //         o.attributes.sheetType === "report" &&
      //         (employeeId === o.attributes.belongsTo.id || employeeId === o.attributes.createdBy.creatorId)
      //     )
      // } else {
      return (
        o.attributes.sheetType === 'report' &&
        (session.user['profile'].employeeId === o.attributes.belongsTo.id ||
          session.user['profile'].employeeId ===
            o.attributes.createdBy.creatorId ||
          session.user['id'] === o.attributes.createdBy.creatorId)
      )
      // }
    })
    // }
    timesheets.reverse().map((timesheet: any) => {
      timesheetOptions.push({
        value: timesheet.id,
        label: timesheet.attributes.name,
        period: timesheet.attributes.period,
        employee_id:
          timesheet.attributes.belongsTo.id ||
          timesheet.attributes.createdBy.creatorId,
      })
    })
  }

  if (tasksData && tasksData.data && tasksData.data.length > 0) {
    tasks = _.filter(tasksData.data, function (o) {
      if (employeeId) {
        return (
          o.attributes.status !== TaskStatus.TO_DO &&
          (employeeId === o.attributes.assignedTo.employeeId ||
            employeeId === o.attributes.assignedTo.id ||
            employeeId === o.attributes.createdBy.creatorId)
        )
      } else {
        return (
          o.attributes.status !== TaskStatus.TO_DO &&
          (session.user['profile'].employeeId === o.attributes.assignedTo.id ||
            session.user['profile'].employeeId ===
              o.attributes.createdBy.creatorId ||
            session.user['id'] === o.attributes.createdBy.creatorId)
        )
      }
    })
    tasks = _.sortBy(tasks, function (t) {
      return t.attributes.name
    })
    tasks.map((task: any) => {
      taskOptions.push({
        value: task.id,
        label: `${task.attributes.projectName} - ${task.attributes.name}`,
      })
    })
  }

  let startDate = moment().startOf('isoWeek')

  const workLog = _.find(workLogs, function (o) {
    return o.id == workLogId
  })

  if (workLog) {
    startDate = moment(workLog.date).startOf('isoWeek')
  }

  const employeeOrganization = _.find(organizationsOptions, [
    'value',
    session?.user['profile']?.organizationId,
  ])

  if (employeeOrganization && !selectedOrganization) {
    setSelectedOrganization(employeeOrganization.value)
  }

  const employee = _.find(employees, ['email', session?.user?.email])

  const renderDayContents = useCallback(
    (momentInstance: moment.Moment, modifiers: Set<string>) => {
      return (
        <>
          <span className="CalendarDayNumber">
            {momentInstance.format('D')}
          </span>
          {/* Add week numbers, adds one button each row */}
          {modifiers.has('first-day-of-week') && (
            <span className="CalendarDayWeekNumber">
              <b>Wk {momentInstance.format('W')}</b>{' '}
            </span>
          )}
        </>
      )
    },
    [],
  )

  return (
    <Formik
      initialValues={{
        description: '',
        duration_unit: 'hours',
        date: undefined,
        employee_id: employeeId,
        task_id:
          taskId || workLog ? (taskId ? taskId : workLog?.task_id) : undefined,
        action: workLog ? 'PUT' : 'POST',
        organization_id: session?.user['profile']?.organizationId,
        ...workLog,
      }}
      onSubmit={(values, formikBag) => {
        setSubmitError(null)
        const task_id = values['task_id']
        const post_action = values?.postAction
        delete values['task_id']
        delete values['timesheet_id']
        let postData: any = JSON.stringify(values)

        const submitData = performRequest(
          workLog
            ? `${process.env.NEXT_PUBLIC_API_URL}/tasks/${
                taskId || task_id
              }/work_logs/${workLog.id}`
            : `${process.env.NEXT_PUBLIC_API_URL}/tasks/${
                taskId || task_id
              }/work_logs`,
          postData,
          values.action,
        )

        submitData
          .then(function (response) {
            formikBag.setSubmitting(false)
            setSuccessMessage(
              `A work log item has been added/updated successfully`,
            )
            if (workLogs) {
              // workLogs.push(values)
            }
            formikBag.resetForm({
              values: {
                description: '',
                duration_unit: 'hours',
                duration: '',
                date: undefined,
                employee_id:
                  selectedTimesheetPeriod?.employee_id || employee_id,
                task_id: taskId || workLog ? workLog?.task_id : '',
                // timesheet_id: '',
                action: workLog ? 'PUT' : 'POST',
              },
            })
            mutate(
              process.env.NEXT_PUBLIC_API_URL + `/tasks/${taskId || task_id}`,
            )
            if (post_action && post_action == 'close') {
              setTimeout(function () {
                props?.setWorkLogModalOpen(false)
              }, 1000)
            } else {
              formikBag.setSubmitting(false)
              formikBag.resetForm({
                values: {
                  description: '',
                  duration_unit: 'hours',
                  duration: '',
                  date: undefined,
                  employee_id:
                    selectedTimesheetPeriod?.employee_id || employee_id,
                  task_id: taskId || workLog ? workLog?.task_id : '',
                  // timesheet_id: '',
                  action: workLog ? 'PUT' : 'POST',
                },
              })
              setTimeout(function () {
                // setWorkLogModalOpen(false)
                setSuccessMessage('You can add another one or close this modal')
                // formikBag.resetForm()
              }, 3000)
            }
          })
          .catch(function (error) {
            formikBag.setSubmitting(false)
            formikBag.resetForm({})
            setSubmitError(null)
            if (error.response) {
              setSubmitError(
                error.response.data.message || error.response.data.error,
              )
            } else if (error.request) {
              setSubmitError(
                'There seems to be an issue talking to our server. Please check your network connection and try again',
              )
            }
          })
      }}
      validationSchema={Yup.object().shape({
        action: Yup.string(),
        duration_unit: Yup.string().required('Select a unit'),
        duration: Yup.number()
          .when('duration_unit', {
            is: (val) => val === 'days',
            then: Yup.number().max(1, 'not more than 1 day'),
            otherwise: Yup.number().required('Enter a duration'),
          })
          .when('duration_unit', {
            is: (val) => val === 'minutes',
            then: Yup.number().max(600, 'not more than 600 minutes'),
            otherwise: Yup.number().required('Enter a duration'),
          })
          .when('duration_unit', {
            is: (val) => val === 'hours',
            then: Yup.number().max(10, 'not more than 10 hours'),
            otherwise: Yup.number().required('Enter a duration'),
          }),
        description: Yup.string().required('Briefly, what did you do?'),
        date: Yup.date().required('When did you do this?'),
        task_id: Yup.string().required('Select this to assign the work log to'),
        // timesheet_id: Yup.string().when('action', {
        //     is: (val) => val === 'POST',
        //     then: Yup.string().required('Select this to set a week'),
        //     otherwise: Yup.string().nullable()
        // })
      })}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setSubmitting,
          setFieldValue,
        } = props

        return (
          <>
            <form>
              {submitError && (
                <Alert alertStyle="danger" lightMode>
                  There was a problem submitting : {submitError}
                </Alert>
              )}
              {successMessage && (
                <Alert alertStyle="success" lightMode>
                  {successMessage}
                </Alert>
              )}
              {isAdmin(session) && (
                <>
                  <Box width={[1]}>
                    <InputGroup label="Organization">
                      <Field name="organization_id" id={'organization_id'}>
                        {({ field, form, meta }) => (
                          <SSelect
                            id={field.name}
                            invalid={meta.error}
                            invalidText={meta.error}
                            onChange={(value) => {
                              setSelectedOrganization(value.value)
                              setFieldValue(field.name, value.value)
                            }}
                            onBlur={handleBlur}
                            controlSpecificProps={{
                              isSearchable: false,
                              isDisabled:
                                organizationsOptions.length < 1 || values?.id,
                              isLoading: organizationsOptions.length < 1,
                              loadingMessage: 'Loading...',
                              noOptionsMessage: 'No organizations',
                            }}
                            selectedOption={organizationsOptions.find(
                              (option) => option.value === values[field.name],
                            )}
                            options={organizationsOptions}
                            defaultValue={employeeOrganization}
                          />
                        )}
                      </Field>
                    </InputGroup>
                  </Box>
                  <Box width={[1]}>
                    <InputGroup label="Employee">
                      <Field name="employee_id" id={'employee_id'}>
                        {({ field, form, meta }) => (
                          <SSelect
                            id={field.name}
                            invalid={meta.error}
                            invalidText={meta.error}
                            onChange={(value) => {
                              setFieldValue(field.name, value.value)
                              setSelectedEmployee(value.value)
                              setFieldValue('task_id', '')
                            }}
                            onBlur={handleBlur}
                            controlSpecificProps={{
                              isSearchable: false,
                              isDisabled: employees.length < 1 || values?.id,
                              isLoading: employees.length < 1,
                              loadingMessage: 'Loading...',
                            }}
                            selectedOption={employees.find(
                              (option) => option.value === values[field.name],
                            )}
                            options={employees}
                          />
                        )}
                      </Field>
                    </InputGroup>
                  </Box>
                </>
              )}
              {!taskId ? (
                <Box width={[1]}>
                  <InputGroup label="Select a task to assign this work log">
                    <Field name="task_id" id={'task_id'}>
                      {({ field, form, meta }) => (
                        <SSelect
                          id={field.name}
                          invalid={meta.error}
                          invalidText={meta.error}
                          onChange={(value) => {
                            setFieldValue(field.name, value.value)
                          }}
                          onBlur={handleBlur}
                          controlSpecificProps={{
                            isSearchable: true,
                            isDisabled: taskOptions.length < 1,
                            isLoading: taskOptions.length < 1,
                            loadingMessage: 'Loading...',
                          }}
                          selectedOption={
                            values[field.name]
                              ? taskOptions.find(
                                  (option) =>
                                    option.value === values[field.name] || '',
                                )
                              : { value: '', label: '' }
                          }
                          options={[{ value: '', label: '' }, ...taskOptions]}
                        />
                      )}
                    </Field>
                  </InputGroup>
                </Box>
              ) : (
                <Box width={[1]}>
                  <InputGroup label="Task">{taskName}</InputGroup>
                </Box>
              )}
              <Box width={[1]}>
                <InputGroup label="What did you do on this task?">
                  <Field name="description" id={'description'}>
                    {({ field, form, meta }) => (
                      <Textarea
                        id="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={meta.touched && meta.error}
                        invalidText={meta.error}
                        maxLength={1000}
                        required={false}
                        rows={4}
                        value={values[field.name]}
                        {...field}
                      />
                    )}
                  </Field>
                </InputGroup>
              </Box>
              <Flex width={[1]} flexWrap={'wrap'}>
                <Box width={[1, 1 / 2]} pr={'16px'}>
                  <InputGroup label="Which day did you do it?">
                    <Field name={'date'} id={'date'}>
                      {({ field, form, meta }) => {
                        // if (selectedTimesheetPeriod) {
                        //     let m = moment().set({ year: selectedTimesheetPeriod.year, week: selectedTimesheetPeriod.week })
                        //     if (m) {
                        //         if (selectedTimesheetPeriod.day) {
                        //             m.dayOfYear(selectedTimesheetPeriod.day)
                        //         }
                        //         m = m.isoWeek(selectedTimesheetPeriod.week)
                        //         startDate = m.startOf('isoWeek')
                        //         endDate = m.endOf('isoWeek')
                        //     }
                        // }

                        return (
                          <SingleDatePicker
                            date={
                              values[field.name]
                                ? moment(values[field.name])
                                : undefined
                            }
                            onDateChange={(date) => setFieldValue('date', date)}
                            focused={startDateFocus}
                            onFocusChange={({ focused }) =>
                              setStartDateFocus(focused)
                            }
                            id="date"
                            block
                            withPortal={true}
                            numberOfMonths={1}
                            placeholder={''}
                            initialVisibleMonth={() => startDate}
                            renderDayContents={renderDayContents}
                            // isDayBlocked={day => day.isBetween(startDate.clone().subtract(1, 'days'), endDate)}
                            // isOutsideRange={day =>
                            //     !isInclusivelyAfterDay(day, moment(startDate.clone())) ||
                            //     isInclusivelyAfterDay(day, moment(startDate).add(1, 'week'))
                            // }
                            isOutsideRange={(day) =>
                              isInclusivelyAfterDay(
                                day,
                                moment().add(1, 'days'),
                              )
                            }
                            displayFormat="DD/MM/YYYY"
                            firstDayOfWeek={1}
                          />
                        )
                      }}
                    </Field>
                  </InputGroup>
                </Box>
                <Box width={[1, 1 / 3]}>
                  <InputGroup label="For how long?">
                    <Field name="duration" id={'duration'}>
                      {({ field, form, meta }) => (
                        <Input
                          id="duration"
                          type="number"
                          value={values[field.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={meta.touched && meta.error}
                          invalidText={meta.error}
                          inputSize="lg"
                          autoComplete="off"
                          required={true}
                          addonText={meta.touched && meta.error ? '*' : null}
                          addonTextPosition="right"
                          {...field}
                        />
                      )}
                    </Field>
                  </InputGroup>
                </Box>
                <Box width={[1, 1 / 6]} style={{ marginTop: '32px' }}>
                  <SSelect
                    id="duration_unit"
                    onChange={(value) => {
                      setFieldValue('duration_unit', value.value)
                    }}
                    onBlur={handleBlur}
                    selectedOption={timeSuffixList.find(
                      (option) => option.value === values.duration_unit,
                    )}
                    controlSpecificProps={{
                      isSearchable: false,
                      id: 'duration_unit',
                      name: 'duration_unit',
                    }}
                    options={timeSuffixList}
                    style={{
                      borderLeft: 'none',
                    }}
                  />
                </Box>
              </Flex>
              <br />
              {workLog ? (
                <Button
                  btnStyle="primary"
                  btnSize="md"
                  disabled={!isValid || isSubmitting}
                  onClick={async (e) => {
                    e.preventDefault()
                    await setFieldValue('postAction', 'close')
                    handleSubmit()
                  }}
                  outline={true}
                  type="submit"
                >
                  {isSubmitting ? (
                    <span>Updating...</span>
                  ) : (
                    <span>Update</span>
                  )}
                </Button>
              ) : (
                <>
                  <Button
                    btnStyle="primary"
                    btnSize="md"
                    disabled={!isValid || isSubmitting}
                    onClick={async (e) => {
                      e.preventDefault()
                      //await setFieldValue("submitType", "new");
                      handleSubmit()
                    }}
                    type="submit"
                    style={{ marginRight: '16px' }}
                  >
                    {isSubmitting ? (
                      <span>Submitting...</span>
                    ) : (
                      <span>Submit &amp; Add</span>
                    )}
                  </Button>
                  <Button
                    btnStyle="primary"
                    btnSize="md"
                    disabled={!isValid || isSubmitting}
                    onClick={async (e) => {
                      e.preventDefault()
                      await setFieldValue('postAction', 'close')
                      handleSubmit()
                    }}
                    outline={true}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <span>Submitting...</span>
                    ) : (
                      <span>Submit &amp; Close</span>
                    )}
                  </Button>
                </>
              )}
            </form>
          </>
        )
      }}
    </Formik>
  )
}
