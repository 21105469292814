// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://77e64698a3c34954ab50e386170c5f4b@o1152138.ingest.sentry.io/6229978',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend(event, hint) {
    // Suppress logging of null user errors
    if (
      event.message !=
        'Wrong credentials. Try again or contact support for help.' &&
      event.message != 'CredentialsSignin'
    ) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && process.env.NEXT_APP_ENV === 'production') {
        // Sentry.showReportDialog({ eventId: event.event_id })
      }
    }
    return event
  },
})
