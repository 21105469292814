import { Box } from '@rebass/grid'
import { Badge, Button, Navbar, Select, Spinner, Tabnav } from '@tkxs/cast-ui'
import { DateRangePicker } from 'react-dates'
import styled from 'styled-components'

interface LoaderProps {
  loading?: boolean
}
interface Props {
  pageProps?: any
  theme?: any
}
export interface SessionUser {
  name?: string | null
  email?: string | null
  image?: string | null
  type?: string | null
  accessToken?: string | null
  id?: string | null
  links?: {} | null
  profile?: {
    employeeId?: string
    department?: string
    employeeName?: string
    organization?: string
    organizationId?: string
    worksite?: string
  } | null
  subordinates?: []
  managers?: {
    employeeId?: string
    department?: string
    employeeName?: string
    organization?: string
    organizationId?: string
    worksite?: string
  }[]
}

export const Loader = styled.div<LoaderProps>`
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: 110px;
  height: 100%;
  transform: none;
  .spinner {
    transform: none !important;
    top: auto !important;
  }
`

interface SpinnerPropsExtra {
  className?: any
}

export const SSpinner = styled(Spinner)<SpinnerPropsExtra>`
  &::before {
    width: ${(props) => (props.size ? props.size + 'px' : '50px')};
    height: ${(props) => (props.size ? props.size + 'px' : '50px')};
  }
`

export const PrimaryNavbar = styled(Navbar)`
  padding: 0;
  border-bottom: 1px solid #dddddd;
  height: 81px;
`

export const ControlNavbar = styled(PrimaryNavbar)`
  padding: 0;
  border-bottom: 1px solid #dddddd;
  height: 81px;
  margin: -10px -10px 10px -10px;
  padding: 0 10px;
`

export const ControlTabnav = styled(Tabnav)`
  padding: 0;
  margin: -10px -10px 0px -10px;
  padding: 0 10px;
  border-top: 1px solid #dddddd;
  [role='tab'] {
    padding: 16px 0 8px;
  }
  margin-bottom: 10px;
`

export const SLink = styled.a`
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  color: ${(props: Props) => props.theme.colors.primary};
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export const SBadge = styled(Badge)`
  padding: 3px 10px;
  text-transform: capitalize;
  font-weight: normal;
  padding: 5px 8px;
`

export const SBoxImage = styled(Box)`
  position: relative;
  display: block;
`

export const SButton = styled(Button)`
  width: 100%;
`

export const SSelect = styled(Select)`
  padding: 0;
  width: 100%;
  display: block;
  .react-select__value-container,
  .css-1g6gooi {
    padding: 0 !important;
  }
  .css-1g6gooi {
    margin: 0;
  }
`

export const SDateRangePicker = styled(DateRangePicker)`
  z-index: 200000;
  width: 100% !important;
  .DateRangePickerInput {
    width: 100% !important;
  }
  .DateInput {
    width: auto !important;
  }
`

export const Container = styled(Box)`
  max-width: 1300px;
  width: 100%;
`

export const Divider = styled.div`
  padding-left: 5px;
  margin-right: 19px;
  margin-left: 19px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  height: 30px;
`

export const ActionButton = styled(Button)`
  border-radius: 20px;
`
