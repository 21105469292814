import * as Sentry from '@sentry/nextjs'
import { ThemeProvider } from '@tkxs/cast-ui'
import _ from 'lodash'
import moment from 'moment'
import { getSession, SessionProvider } from 'next-auth/react'
import { AppProps } from 'next/app'
import { useRouter, withRouter } from 'next/router'
import Script from 'next/script'
import 'normalize.css/normalize.css'
import { useEffect, useState } from 'react'
import { Loader, SessionUser, SSpinner } from '../common/common'
import AppWrapper from '../components/AppWrapper'
import '../styles/globals.scss'
import { tezzaTheme } from '../styles/theme'
import { isAdmin, isHr } from '../utils/auth'

// axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL + "/";
// axios.defaults.withCredentials = false;

export function reportWebVitals({ id, name, label, value }) {
  // Use `window.gtag` if you initialized Google Analytics as this example:
  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
  if (typeof window !== 'undefined' && window.gtag) {
    try {
      window.gtag('event', name, {
        event_category:
          label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        event_label: id, // id unique to current page load
        non_interaction: true, // avoids affecting bounce rate.
      })
    } catch (error) {}
  }
}

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  moment['tz']?.setDefault('Africa/Nairobi')

  const [pageLoading, setPageLoading] = useState(false)
  const [authCheck, setAuthCheck] = useState(false)
  const [sessionUser, setSessionUser] = useState<SessionUser>()

  useEffect(() => {
    const handleStart = (url) => {
      setPageLoading(true)
    }
    const handleStop = () => {
      setPageLoading(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('beforeHistoryChange', handleStart)
    router.events.on('hashChangeStart', handleStart)
    router.events.on('hashChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.on('beforeHistoryChange', handleStart)
      router.events.on('hashChangeStart', handleStart)
      router.events.on('hashChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  useEffect(() => {
    try {
      const s = getSession()
      setAuthCheck(true)
      Promise.resolve(s)
        .then(function (value) {
          const authPages = [
            '/login',
            '/register',
            '/forgot',
            '/reset_password',
            '/404',
          ]
          const controlPages = ['edit', 'new', 'users']
          const whitelistControlPages = ['projects', 'timesheets', 'tasks']
          if (authPages.includes(router.pathname) && _.has(value, 'user')) {
            router.push('/')
          }
          if (!authPages.includes(router.pathname) && _.isEmpty(value)) {
            router.push(`/login?returnTo=${router.asPath}`)
            // window.location.replace('/login')
          }
          if (
            !whitelistControlPages.includes(
              String(router.pathname).split('/')[1],
            )
          ) {
            if (
              !isAdmin(value) &&
              !isHr(value) &&
              controlPages.includes(_.last(String(router.pathname).split('/')))
            ) {
              router.push(
                String(router.pathname)
                  .replace('/new', '')
                  .replace('/edit', '')
                  .replace('users', ''),
              )
            }
          }
          if (_.has(value, 'user')) {
            const session: SessionUser = value.user
            Sentry.setUser({
              id: session.id,
              email: session.email,
              name: session.name,
            })
            setSessionUser(session)
          }
          setAuthCheck(false)
        })
        .catch((error) => {
          Sentry.configureScope(function (scope) {
            scope.setUser(null)
            scope.setLevel(Sentry.Severity.Log)
            Sentry.captureMessage(error)
          })
          setAuthCheck(false)
        })
    } catch (e) {}
  }, [router])

  if (typeof window !== 'undefined') {
    if (
      window.location.protocol === 'http:' &&
      window.location.hostname !== 'localhost'
    ) {
      const httpsUrl = String(window.location.href).replace('http:', 'https:')
      window.location.replace(httpsUrl)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const pathnames = _.split(_.replace(router?.pathname, '/', ''), '/')
        document.body.classList.add(
          pathnames && pathnames.length > 0 ? pathnames[0] : 'dashboard',
        )
      } catch (e) {}
    }
  })

  return (
    <SessionProvider>
      <ThemeProvider theme={tezzaTheme}>
        {process.env.NEXT_APP_ENV === 'production' && (
          <Script
            id="ms-clarity"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "da0poerv66");
          `,
            }}
          />
        )}
        {!authCheck ? (
          <AppWrapper
            Component={Component}
            pageProps={{ ...pageProps, pageLoading: pageLoading }}
            router={router}
          />
        ) : (
          <Loader className="-loading -active">
            <SSpinner className="spinner" size={30} />
          </Loader>
        )}
      </ThemeProvider>
    </SessionProvider>
  )
}

export default withRouter(CustomApp)
