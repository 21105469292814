import * as Sentry from '@sentry/nextjs'
import {
  Button,
  Card,
  ListGroup,
  ListGroupItem,
  Modal,
  Popover,
  Title,
} from '@tkxs/cast-ui'
import _ from 'lodash'
import { signOut, useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { SessionUser } from '../common/common'
import { performRequest } from '../pages/api'
import PasswordChangeForm from './forms/PasswordChange'

const InitialsLabel = styled.a`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  align-contents: center;
  border-radius: 100%;
  border: 1px solid #aa1e2b;
  color: #aa1e2b;
  font-size: 25px;
  cursor: pointer;
  &:hover,
  &:focus,
  &.active {
    background: #aa1e2b;
    color: #fff;
    cursor: pointer;
  }
`
const SCard = styled(Card)`
  min-width: 300px;
  text-align: right;
  li {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

export default function UserCard(props: any) {
  const { data: session, status } = useSession()
  const [menuOpen, toggleMenuOpen] = useState(false)
  const [passwordModalOpen, togglePasswordModalOpen] = useState(false)

  const sessionUser: SessionUser = session && session?.user

  const initials = _.join(
    _.map(_.split(_.startCase(session.user.name), ' '), function (e) {
      return e.charAt(0)
    }),
    '',
  )

  useEffect(() => {
    if (menuOpen) {
      setTimeout(function () {
        toggleMenuOpen(false)
      }, 10000)
    }
  }, [menuOpen])
  function UserInformation() {
    return (
      <SCard bgColor="#ffffff" highlightBorder="top">
        <Title size={10}>
          {session?.user.name || ''}
          <small
            style={{
              fontWeight: 'normal',
              display: 'block',
              lineHeight: 'normal',
            }}
          >
            <a
              href={`mailto:${session.user.email}`}
              title="Send an email"
              rel="noreferrer"
            >
              {session.user.email}
            </a>
          </small>
          {session?.user['type'] ? (
            <small
              style={{
                fontWeight: 'normal',
                display: 'block',
                lineHeight: 'normal',
              }}
            >
              {_.replace(session?.user['type'], 'User', '') || ''}
            </small>
          ) : (
            <small style={{ fontWeight: 'normal', display: 'block' }}>
              User
            </small>
          )}
        </Title>
        <hr />
        <ListGroup>
          <ListGroupItem>
            <Flex flexDirection={'row'} justifyContent="space-between">
              <b>Department</b>
              <span>{sessionUser?.profile?.department || '-'}</span>
            </Flex>
          </ListGroupItem>
          <ListGroupItem>
            <Flex flexDirection={'row'} justifyContent="space-between">
              <b>Work Site</b>
              <span>{sessionUser?.profile?.worksite || '-'}</span>
            </Flex>
          </ListGroupItem>
          <ListGroupItem>
            <Flex flexDirection={'row'} justifyContent="space-between">
              <b>Manager</b>
              <span>
                {sessionUser?.managers && sessionUser?.managers?.length > 0
                  ? sessionUser?.managers[0].employeeName
                  : 'N/A'}
              </span>
            </Flex>
          </ListGroupItem>
        </ListGroup>
        <Button
          id="changePassword"
          outline={true}
          style={{ display: 'block', width: '100%', marginTop: '8px' }}
          onClick={() => {
            toggleMenuOpen(false)
            togglePasswordModalOpen(!passwordModalOpen)
          }}
          btnSize={'md'}
        >
          Change Password
        </Button>
        <Button
          id="signOut"
          style={{ display: 'block', width: '100%', marginTop: '8px' }}
          onClick={() =>

            performRequest(
              process.env.NEXT_PUBLIC_API_URL + 'logout',
              {},
              'DELETE',
            )
              .then(function (response) {
                try {
                  localStorage.clear()
                } catch (e) {

                }
                signOut({
                  callbackUrl: `/login`,
                })
              })
              .catch(function (error) {
                try {
                  localStorage.clear()
                } catch (e) {

                }
                Sentry.configureScope(function (scope) {
                  scope.setLevel(Sentry.Severity.Log)
                  Sentry.captureMessage(error)
                })
                signOut({
                  callbackUrl: `/login`,
                })
              })
          }
          btnSize={'lg'}
          btnStyle={'danger'}
        >
          Sign Out
        </Button>
      </SCard >
    )
  }

  return (
    <>
      <Popover
        arrow
        content={<UserInformation />}
        isVisible={menuOpen}
        placement="bottom-end"
        size="regular"
      >
        <span>
          <InitialsLabel
            onClick={(e) => {
              e.preventDefault()
              toggleMenuOpen(!menuOpen)
            }}
            className={menuOpen ? 'active' : ''}
          >
            <b style={{ textAlign: 'center', margin: 'auto' }}>{initials}</b>
          </InitialsLabel>
        </span>
      </Popover>
      <Modal
        isOpen={passwordModalOpen}
        id="passwordModal"
        modalTitle="Change Password"
        onTitleClose={(e) => {
          toggleMenuOpen(false)
          togglePasswordModalOpen(false)
        }}
        footerContent={null}
        modalSize="sm"
      >
        <PasswordChangeForm closeModal={togglePasswordModalOpen} />
      </Modal>
    </>
  )
}
