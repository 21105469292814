import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import Cookies from 'js-cookie'

export const getUtilization = (minutes: number, frequency: string | number) => {
  const hours = {
    daily: 8,
    weekly: 40,
    monthly: 160,
    annual: 1920,
  }

  const utilization_value = (minutes / (hours[frequency] * 60)) * 100

  if (utilization_value >= 100) {
    return {
      label: 'Overutilized',
      value: parseFloat(utilization_value.toFixed(0)),
      style: 'danger',
      color: 'red',
    }
  } else if (utilization_value > 75) {
    return {
      label: 'Careful',
      value: parseFloat(utilization_value.toFixed(0)),
      style: 'warning',
      color: 'orange',
    }
  } else if (utilization_value > 60) {
    return {
      label: 'Normal',
      value: parseFloat(utilization_value.toFixed(0)),
      style: 'success',
      color: 'green',
    }
  } else if (utilization_value < 60) {
    return {
      label: 'Underutilized',
      value: parseFloat(utilization_value.toFixed(0)),
      style: 'secondary',
      color: 'black',
    }
  } else {
    return {
      label: '',
      value: 0,
      style: 'secondary',
      color: '#ccc',
    }
  }
}

export function getUtilizationColor(utilization_value: number): {
  label: string
  value: number
  style: any
  color: string
} {
  if (utilization_value >= 100) {
    return {
      label: 'Overutilized',
      value: parseFloat(utilization_value.toFixed(0)),
      style: 'danger',
      color: 'red',
    }
  } else if (utilization_value >= 75) {
    return {
      label: 'Careful',
      value: parseFloat(utilization_value.toFixed(0)),
      style: 'warning',
      color: 'orange',
    }
  } else if (utilization_value >= 60) {
    return {
      label: 'Normal',
      value: parseFloat(utilization_value.toFixed(0)),
      style: 'success',
      color: 'green',
    }
  } else if (utilization_value < 60) {
    return {
      label: 'Underutilized',
      value: parseFloat(utilization_value.toFixed(0)),
      style: 'secondary',
      color: 'black',
    }
  } else {
    return {
      label: 'Unknown',
      value: 0,
      style: 'secondary',
      color: '#ccc',
    }
  }
}

export const useLocalStorage = (storageKey: string, fallbackState: boolean) => {
  const [value, setValue] = React.useState(
    JSON.parse(
      typeof window !== 'undefined' && localStorage.getItem(storageKey),
    ) ?? fallbackState,
  )

  React.useEffect(() => {
    typeof window !== 'undefined' &&
      localStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

export const useCookies = (
  cookieKey: string,
  fallbackState: any,
  cookieOptions?: any,
) => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && Cookies.get(cookieKey)
      ? JSON.parse(Cookies.get(cookieKey))
      : fallbackState,
  )

  React.useEffect(() => {
    typeof window !== 'undefined' &&
      Cookies.set(cookieKey, JSON.stringify(value), cookieOptions)
  }, [value, cookieKey, cookieOptions])

  return [value, setValue]
}

export const getMonthsList = () => {
  const months = []
  const dateStart = moment()
  const dateEnd = moment().add(12, 'month')
  while (dateEnd.diff(dateStart, 'months') >= 0) {
    months.push({
      label: dateStart.format('MMMM'),
      value: parseInt(dateStart.format('M')),
    })
    dateStart.add(1, 'month')
  }
  return _.uniqBy(_.sortBy(months, 'value'), 'value')
}

export const getYearsList = (range: number) => {
  const years = []
  const dateStart = moment()
  const dateEnd = moment().subtract(range, 'y')
  while (dateEnd.diff(dateStart, 'years') <= range) {
    years.push({
      label: dateStart.format('YYYY'),
      value: parseInt(dateStart.format('YYYY')),
    })
    dateStart.subtract(1, 'years')
  }
  return _.uniqBy(_.sortBy(years, 'value'), 'value').reverse()
}
