import { Alert, Button, Input, InputGroup, Textarea } from '@tkxs/cast-ui'
import { Field, Formik } from 'formik'
import _ from 'lodash'
import { useSession } from 'next-auth/react'
import { useState } from 'react'
import { Box, Flex } from 'rebass'
import useSWR from 'swr'
import * as Yup from 'yup'
import { Loader, SessionUser, SSelect, SSpinner } from '../../common/common'
import { riskStatus } from '../../common/constants'
import { performRequest } from '../../pages/api'
import { isAdmin } from '../../utils/auth'
import { camelizeKeys } from '../../utils/converters'

export default function RiskForm(props: any) {
  const { data: session, status } = useSession()
  const { projectId, selectedRisk, addToTimesheet, setRiskModalOpen } = props

  const user: SessionUser = camelizeKeys(session?.user)
  const profile: any = user && user['profile']

  const [successMessage, setSuccessMessage] = useState('')
  const [submitError, setSubmitError] = useState('')

  const { data, error } = useSWR('/api/cache?cache=projects')

  if (error && error.response) {
    return isAdmin(session) ? (
      <Alert alertStyle="danger" lightMode>
        <b>
          HTTP Status {error.response.status} ({error.response.statusText}):
        </b>{' '}
        {error.response.data.message}
      </Alert>
    ) : (
      <Alert alertStyle="danger" lightMode>
        <b>Error:</b> There was an issue retrieving Projects. Refresh.
      </Alert>
    )
  }

  if (!data) {
    return (
      <Loader className="-loading -active">
        <SSpinner className="spinner" size={30} />
      </Loader>
    )
  }

  let projects = data ? camelizeKeys(data.data) : null

  if (!isAdmin(session)) {
    projects = _.filter(projects, function (project) {
      if (
        project.attributes.createdBy?.creatorId === user.id ||
        project.attributes.ownedBy?.organization === profile.organization
      ) {
        return project
      }
      if (
        project.attributes.ownedBy.type === 'Private' &&
        project.attributes.createdBy?.creatorId !==
          (profile.employeeId || user.id)
      ) {
        return project
      }
      if (
        project.attributes.ownedBy?.organizationId !==
        (profile.organizationId || profile.organization)
      ) {
        return undefined
      }
      return (
        project.attributes.ownedBy.type === 'Organization Wide' ||
        project.attributes.ownedBy?.employeeId === profile.employeeId ||
        project.attributes.createdBy?.creatorId !==
          (profile.employeeId || user.id) ||
        project.attributes.ownedBy?.department === profile.department
      )
    })
  }

  let projectsOptions = []

  if (projects) {
    projects = projects.map((project: any) => {
      if (project?.attributes?.status === 'active') {
        projectsOptions.push({
          value: project.id,
          label: `${_.trim(project.attributes.name)} - ${_.trim(
            project.attributes.ownedBy.type,
          )}`,
        })
      }
    })
  }

  projectsOptions = _.unionBy(_.sortBy(projectsOptions, ['label']), 'value')

  return (
    <Formik
      initialValues={{
        id: selectedRisk?.id,
        name: selectedRisk?.attributes?.name || '',
        description: selectedRisk?.attributes?.description || '',
        probability: selectedRisk?.attributes?.probability || '',
        severity: selectedRisk?.attributes?.severity || '',
        user_id: user.id,
        creator_id: user.id,
        impact: selectedRisk?.attributes?.impact || '',
        project_id: projectId || projects[0]?.attributes?.id,
      }}
      onSubmit={(values, formikBag) => {
        setSubmitError(null)
        const post_action = values['postAction']
        let postData: any = JSON.stringify({ risk: values })

        const submitData = performRequest(
          process.env.NEXT_PUBLIC_API_URL +
            `/projects/${values.project_id}/risks/${values?.id || ''}`,
          postData,
          values.id ? 'PUT' : 'POST',
        )

        submitData
          .then(function (response) {
            setSuccessMessage(
              `A project risk item has been added/updated successfully`,
            )

            if (post_action && post_action == 'close') {
              setTimeout(function () {
                props?.setRiskModalOpen(false)
              }, 1000)
            } else {
              formikBag.resetForm({
                values: {
                  id: selectedRisk?.id,
                  name: selectedRisk?.attributes?.name || '',
                  description: selectedRisk?.attributes?.description || '',
                  probability: selectedRisk?.attributes?.probability || '',
                  severity: selectedRisk?.attributes?.severity || '',
                  user_id: user.id,
                  creator_id: user.id,
                  impact: selectedRisk?.attributes?.impact || '',
                  project_id: projectId || projects[0]?.attributes?.id,
                },
              })
            }
            formikBag.setSubmitting(false)
          })
          .catch(function (error) {
            formikBag.setSubmitting(false)
            setSubmitError(null)
            if (error.response) {
              setSubmitError(
                error.response.data.message || error.response.data.error,
              )
            } else if (error.request) {
              setSubmitError(
                'There seems to be an issue talking to our server. Please check your network connection and try again',
              )
            }
          })
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        probability: Yup.string().required('Required'),
        severity: Yup.string().required('Required'),
        project_id: Yup.string().required('Required'),
      })}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setSubmitting,
          setFieldValue,
          validateForm,
          resetForm,
        } = props

        return (
          <>
            <form>
              {submitError && (
                <Alert alertStyle="danger" lightMode>
                  There was a problem submitting : {submitError}
                </Alert>
              )}
              {successMessage && (
                <Alert alertStyle="success" lightMode>
                  {successMessage}
                </Alert>
              )}
              {!projectId && (
                <Box width={[1]}>
                  <InputGroup label="Select a project to add a risk to">
                    <Field name="project_id" id={'project_id'}>
                      {({ field, form, meta }) => (
                        <SSelect
                          id={field.name}
                          invalid={meta.error}
                          invalidText={meta.error}
                          onChange={(value) => {
                            setFieldValue(field.name, value.value)
                            setFieldValue('project_name', value.label)
                          }}
                          onBlur={handleBlur}
                          controlSpecificProps={{
                            isSearchable: true,
                            isDisabled: projectsOptions.length < 1,
                            isLoading: projectsOptions.length < 1,
                            loadingMessage: 'Loading...',
                          }}
                          selectedOption={projectsOptions.find(
                            (option) =>
                              option.value === values[field.name] || '',
                          )}
                          options={[
                            { value: '', label: '' },
                            ...projectsOptions,
                          ]}
                        />
                      )}
                    </Field>
                  </InputGroup>
                </Box>
              )}
              <Box width={[1]}>
                <InputGroup label="Risk Name">
                  <Field name="name" id={'name'}>
                    {({ field, form, meta }) => (
                      <Input
                        id="name"
                        type="text"
                        value={values[field.name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={meta.touched && meta.error}
                        invalidText={meta.error}
                        inputSize="lg"
                        autoComplete="off"
                        required={true}
                        addonText={meta.touched && meta.error ? '*' : null}
                        addonTextPosition="right"
                        {...field}
                      />
                    )}
                  </Field>
                </InputGroup>
              </Box>
              <Box width={[1]}>
                <InputGroup label="Describe the risk">
                  <Field name="description" id={'description'}>
                    {({ field, form, meta }) => (
                      <Textarea
                        id="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={meta.touched && meta.error}
                        invalidText={meta.error}
                        maxLength={1000}
                        required={false}
                        rows={4}
                        value={values[field.name]}
                        {...field}
                      />
                    )}
                  </Field>
                </InputGroup>
              </Box>
              <Flex width={[1]} flexWrap={'wrap'}>
                <Box width={[1, 1 / 2]} pr={'16px'}>
                  <InputGroup label="Probability">
                    <Field name={'probability'} id={'probability'}>
                      {({ field, form, meta }) => (
                        <SSelect
                          id={field.name}
                          invalid={meta.error}
                          invalidText={meta.error}
                          onChange={(value) => {
                            setFieldValue(field.name, value.value)
                          }}
                          onBlur={handleBlur}
                          controlSpecificProps={{
                            isSearchable: false,
                            menuPlacement: 'top',
                          }}
                          selectedOption={riskStatus.find(
                            (option) =>
                              option.value === values[field.name] || '',
                          )}
                          options={[{ value: '', label: '' }, ...riskStatus]}
                        />
                      )}
                    </Field>
                  </InputGroup>
                </Box>
                <Box width={[1, 1 / 3]}>
                  <InputGroup label="Severity">
                    <Field name="severity" id={'severity'}>
                      {({ field, form, meta }) => (
                        <SSelect
                          id={field.name}
                          invalid={meta.error}
                          invalidText={meta.error}
                          onChange={(value) => {
                            setFieldValue(field.name, value.value)
                          }}
                          onBlur={handleBlur}
                          controlSpecificProps={{
                            isSearchable: false,
                            menuPlacement: 'top',
                          }}
                          selectedOption={riskStatus.find(
                            (option) =>
                              option.value === values[field.name] || '',
                          )}
                          options={[{ value: '', label: '' }, ...riskStatus]}
                        />
                      )}
                    </Field>
                  </InputGroup>
                </Box>
              </Flex>
              <br />
              {addToTimesheet ? (
                <>
                  <Button
                    btnStyle="primary"
                    btnSize="md"
                    disabled={!isValid || isSubmitting}
                    onClick={async (e) => {
                      e.preventDefault()
                      //await setFieldValue("submitType", "new");

                      validateForm()
                        .then(() => addToTimesheet(values))
                        .then(() => {
                          resetForm({
                            values: {
                              id: selectedRisk?.id,
                              name: selectedRisk?.attributes?.name || '',
                              description:
                                selectedRisk?.attributes?.description || '',
                              probability:
                                selectedRisk?.attributes?.probability || '',
                              severity:
                                selectedRisk?.attributes?.severity || '',
                              user_id: user.id,
                              creator_id: user.id,
                              impact: selectedRisk?.attributes?.impact || '',
                              project_id:
                                projectId || projects[0]?.attributes?.id,
                            },
                          })
                        })
                    }}
                    type="submit"
                    style={{ marginRight: '16px' }}
                  >
                    {isSubmitting ? (
                      <span>Submitting...</span>
                    ) : (
                      <span>Add to Timesheet &amp; Another</span>
                    )}
                  </Button>
                  <Button
                    btnStyle="primary"
                    btnSize="md"
                    disabled={!isValid || isSubmitting}
                    onClick={async (e) => {
                      e.preventDefault()
                      validateForm()
                        .then(() => {
                          addToTimesheet(values)
                        })
                        .then(() => {
                          resetForm({
                            values: {
                              id: selectedRisk?.id,
                              name: selectedRisk?.attributes?.name || '',
                              description:
                                selectedRisk?.attributes?.description || '',
                              probability:
                                selectedRisk?.attributes?.probability || '',
                              severity:
                                selectedRisk?.attributes?.severity || '',
                              user_id: user.id,
                              creator_id: user.id,
                              impact: selectedRisk?.attributes?.impact || '',
                              project_id:
                                projectId || projects[0]?.attributes?.id,
                            },
                          })
                          setTimeout(function () {
                            setRiskModalOpen(false)
                          }, 1000)
                        })
                    }}
                    outline={true}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <span>Submitting...</span>
                    ) : (
                      <span>Add to Timesheet &amp; Close</span>
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    btnStyle="primary"
                    btnSize="md"
                    disabled={!isValid || isSubmitting}
                    onClick={async (e) => {
                      e.preventDefault()
                      //await setFieldValue("submitType", "new");
                      handleSubmit()
                    }}
                    type="submit"
                    style={{ marginRight: '16px' }}
                  >
                    {isSubmitting ? (
                      <span>Submitting...</span>
                    ) : (
                      <span>Submit &amp; Add</span>
                    )}
                  </Button>
                  <Button
                    btnStyle="primary"
                    btnSize="md"
                    disabled={!isValid || isSubmitting}
                    onClick={async (e) => {
                      e.preventDefault()
                      await setFieldValue('postAction', 'close')
                      handleSubmit()
                    }}
                    outline={true}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <span>Submitting...</span>
                    ) : (
                      <span>Submit &amp; Close</span>
                    )}
                  </Button>
                </>
              )}
            </form>
          </>
        )
      }}
    </Formik>
  )
}
