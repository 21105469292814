import { Alert, Button, Input, InputGroup } from '@tkxs/cast-ui'
import { Field, Formik } from 'formik'
import { signOut, useSession } from 'next-auth/react'
import { useState } from 'react'
import { Box, Flex } from 'rebass'
import * as Yup from 'yup'
import { SessionUser } from '../../common/common'
import { performRequest } from '../../pages/api'
import { camelizeKeys } from '../../utils/converters'

export default function PasswordChangeForm(props: any) {
  const { data: session, status } = useSession()
  const { userId, closeModal } = props

  const user: SessionUser = camelizeKeys(session?.user)
  const profile: any = user && user['profile']

  const [successMessage, setSuccessMessage] = useState('')
  const [submitError, setSubmitError] = useState('')

  return (
    <Formik
      initialValues={{
        password: '',
        confirm: '',
      }}
      onSubmit={(values, formikBag) => {
        setSubmitError(null)

        let postData: any = JSON.stringify({ user: values })

        const submitData = performRequest(
          process.env.NEXT_PUBLIC_API_URL + `/users/${userId || user.id}`,
          postData,
          'PUT',
        )

        submitData
          .then(function (response) {
            setSuccessMessage(`User password updated successfully`)
            formikBag.resetForm({
              values: {
                password: '',
                confirm: '',
              },
            })
            performRequest(
              process.env.NEXT_PUBLIC_API_URL + 'logout',
              {},
              'DELETE',
            )
              .then(function (response) {
                signOut({
                  callbackUrl: `/login`,
                })
              })
              .catch(function (error) {
                signOut({
                  callbackUrl: `/login`,
                })
              })
            formikBag.setSubmitting(false)
            setTimeout(function () {
              closeModal(false)
            }, 2000)
          })
          .catch(function (error) {
            formikBag.setSubmitting(false)
            setSubmitError(null)
            if (error.response) {
              setSubmitError(
                error.response.data.message || error.response.data.error,
              )
            } else if (error.request) {
              setSubmitError(
                'There seems to be an issue talking to our server. Please check your network connection and try again',
              )
            }
          })
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required('Password is required')
          .min(8, 'Password is too short - should be 8 chars minimum.'),
        confirm: Yup.string()
          .required('Password confirmation is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      })}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setSubmitting,
          setFieldValue,
          validateForm,
          resetForm,
        } = props

        return (
          <>
            <form>
              {submitError && (
                <Alert alertStyle="danger" lightMode>
                  There was a problem submitting : {submitError}
                </Alert>
              )}
              {successMessage && (
                <Alert alertStyle="success" lightMode>
                  {successMessage}
                </Alert>
              )}
              <Flex flexDirection="column">
                <Box width={[1]}>
                  <InputGroup label="Password">
                    <Field name={'password'} id={'password'}>
                      {({ field, form, meta }) => (
                        <Input
                          {...field}
                          id="password"
                          type="password"
                          value={values[field.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={meta.touched && meta.error}
                          invalidText={meta.error}
                          inputSize="lg"
                          autoComplete="new-password"
                          required={true}
                          addonText={meta.touched && meta.error ? '*' : null}
                          addonTextPosition="right"
                        />
                      )}
                    </Field>
                  </InputGroup>
                </Box>
                <Box width={[1]}>
                  <InputGroup label="Confirm Password">
                    <Field name={'confirm'} id={'confirm'}>
                      {({ field, form, meta }) => (
                        <Input
                          {...field}
                          id="confirm"
                          type="password"
                          value={values[field.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={meta.touched && meta.error}
                          invalidText={meta.error}
                          inputSize="lg"
                          autoComplete="new-password"
                          required={true}
                          addonText={meta.touched && meta.error ? '*' : null}
                          addonTextPosition="right"
                        />
                      )}
                    </Field>
                  </InputGroup>
                </Box>
              </Flex>
              <br />
              <Button
                btnStyle="primary"
                btnSize="lg"
                disabled={!isValid || isSubmitting}
                onClick={async (e) => {
                  e.preventDefault()
                  //await setFieldValue("submitType", "new");
                  handleSubmit()
                }}
                type="submit"
              >
                {isSubmitting ? (
                  <span>Updating...</span>
                ) : (
                  <span>Update Password</span>
                )}
              </Button>
            </form>
          </>
        )
      }}
    </Formik>
  )
}
