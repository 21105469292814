import _ from 'lodash'
import { SessionUser } from '../common/common'

export const isAdmin = (session) => {
  if (session && _.has(session, 'user')) {
    const user: SessionUser = session.user
    return user.type === 'AdminUser'
  }
  return false
}

export const isEmployee = (session) => {
  if (session && _.has(session, 'user')) {
    const user: SessionUser = session.user
    return user.type === 'EmployeeUser' && !_.isEmpty(user.profile)
  }
  return false
}

export const isClient = (session) => {
  if (session && _.has(session, 'user')) {
    const user: SessionUser = session.user
    return user.type === 'ClientUser' && !_.isEmpty(user.profile)
  }
  return false
}

export const isHr = (session) => {
  if (session && _.has(session, 'user')) {
    const user: SessionUser = session.user
    return user.type === 'HrUser'
  }
  return false
}
