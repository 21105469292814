import axios from 'axios'
import fetch from 'isomorphic-unfetch'
import _ from 'lodash'
import moment from 'moment'
import { getSession, signOut } from 'next-auth/react'
import { mutate } from 'swr'
import { camelizeKeys } from '../utils/converters'

var requestHeaders = new Headers()
requestHeaders.append('Content-Type', 'application/json')

const baseUrl = process.env.NEXT_PUBLIC_API_URL + ''

export const fetcher2 = async (url, token) => {
  try {
    const res = await axios
      .get(url, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then(function (response) {
        return camelizeKeys(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            signOut({
              callbackUrl: `/login`,
            })
          }
        } else if (error.request) {
          throw error
        } else {
          throw error
        }
      })

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    // if (!res.ok) {
    //   const error = new Error('An error occurred while fetching the data.')
    //   // Attach extra info to the error object.
    //   error['info'] = await res.json()
    //   error['status'] = res.status
    //   throw error
    // }

    return res
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // signOut({
      //   callbackUrl: `${window.location.origin}/login`,
      // });
    }
    throw err.response?.data
  }
}

export const fetcher = async (url, token?) => {
  const session = await getSession()

  const updatedToken = token
    ? token
    : session
    ? session?.user['accessToken']
    : token

  return await axios
    .get(url, {
      headers: { Authorization: 'Bearer ' + updatedToken },
    })
    .then((res) => camelizeKeys(res.data))
}

export const fetcher3 = async (url, token) => {
  const res = await axios
    .get(url, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(async function (response) {
      return camelizeKeys(response.data)
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      throw error
      return error
    })
}

export function status(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response).catch((error) => {})
  } else {
    return Promise.reject(new Error(response.statusText)).catch((error) => {})
  }
}

export function json(response) {
  return response.json()
}

export async function handleSubmit(path, data, method) {
  mutate(
    path,
    await fetch(path, {
      method: method,
      body: JSON.stringify(data),
    })
      .then(status)
      .then(json)
      .then(function (data) {
        return data
      })
      .catch(function (error) {
        return error
      }),
  )
}

export async function performRequest(path, data?, method?) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const session = await getSession()

  let requestConfig = {
    method: method ? method : 'GET',
    url: path,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + session?.user['accessToken'],
    },
  }

  if (data) {
    return axios({ ...requestConfig, data: data })
  }

  return axios(requestConfig)
}

export const handleOrganizationDelete = (organization, router) => {
  const submitData = performRequest(
    process.env.NEXT_PUBLIC_API_URL + `/organizations/${organization.id}`,
    {},
    'DELETE',
  )
  submitData
    .then(function (response) {
      alert(
        `Organization ${organization.attributes.name} has been deleted successfully`,
      )
      setTimeout(function () {
        router.push(`/organizations`)
      }, 1000)
    })
    .catch(function (error) {
      if (error.response) {
        alert(error.response.data.message)
      } else if (error.request) {
        alert(
          'There seems to be an issue talking to our server. Please check your network connection and try again',
        )
      }
    })
}

export const handleProjectDelete = (project, router, archive?, status?) => {
  const submitData = performRequest(
    process.env.NEXT_PUBLIC_API_URL + `/projects/${project.id}`,
    {
      status: status ? status : 'inactive',
      end_date: new Date().toISOString(),
    },
    archive ? 'PUT' : 'DELETE',
  )
  submitData
    .then(function (response) {
      alert(`Project ${project.attributes.name} has been updated successfully`)
      setTimeout(function () {
        router.push(`/projects`)
      }, 1000)
    })
    .catch(function (error) {
      if (error.response) {
        alert(error.response.data.message)
      } else if (error.request) {
        alert(
          'There seems to be an issue talking to our server. Please check your network connection and try again',
        )
      }
    })
}

export const handleTaskDelete = (task, router) => {
  const submitData = performRequest(
    process.env.NEXT_PUBLIC_API_URL + `/tasks/${task.id}`,
    {},
    'DELETE',
  )
  submitData
    .then(function (response) {
      alert(`Task ${task.attributes.name} has been deleted successfully`)
      setTimeout(function () {
        router.push(`/tasks`)
      }, 1000)
    })
    .catch(function (error) {
      if (error.response) {
        alert(error.response.data.message)
      } else if (error.request) {
        alert(
          'There seems to be an issue talking to our server. Please check your network connection and try again',
        )
      }
    })
}

export const handleTimesheetDelete = (task, router) => {
  const submitData = performRequest(
    process.env.NEXT_PUBLIC_API_URL + `/timesheets/${task.id}`,
    {},
    'DELETE',
  )
  submitData
    .then(function (response) {
      alert(`Timesheet ${task.attributes.name} has been deleted successfully`)
      setTimeout(function () {
        router.push(`/timesheets`)
      }, 1000)
    })
    .catch(function (error) {
      if (error.response) {
        alert(error.response.data.message)
      } else if (error.request) {
        alert(
          'There seems to be an issue talking to our server. Please check your network connection and try again',
        )
      }
    })
}

export const handleObjectDelete = (object, router) => {
  const submitData = performRequest(
    process.env.NEXT_PUBLIC_API_URL +
      `/organizations/${router.query.id}/${_.snakeCase(object.type)}/${
        object.id
      }`,
    { _delete: 1 },
    'DELETE',
  )
  submitData
    .then(function (response) {
      alert(`${object.attributes.name} has been deleted successfully`)
      setTimeout(function () {
        router.push(
          `/organizations/${router.query.id}/${_.replace(
            object.type,
            '-',
            '',
          )}/`,
        )
      }, 1000)
    })
    .catch(function (error) {
      if (error.response) {
        alert('There seems to be an error deleting this')
      } else if (error.request) {
        alert(
          'There seems to be an issue talking to our server. Please check your network connection and try again',
        )
      }
    })
}

export const handleTimesheetApproval = (timesheet, router, approver_id) => {
  delete timesheet['organization_id']
  delete timesheet['relationships']

  let postData: any = {
    creator_id: timesheet.attributes.createdBy.creatorId,
    sheet_frequency: timesheet.attributes.sheetFrequency,
    sheet_type: timesheet.attributes.sheetType,
    day: timesheet.attributes.period?.day,
    month: timesheet.attributes.period?.month,
    week: timesheet.attributes.period?.week,
    year: timesheet.attributes.period?.year,
    employee_id: timesheet.attributes.belongsTo.id,
    tasks: timesheet.attributes.tasks.map((task: any) => {
      return task.id
    }),
    approved: true,
    approved_at: moment().toISOString(),
    approver_id: approver_id,
  }

  postData = JSON.stringify({ timesheet: postData })

  const submitData = performRequest(
    process.env.NEXT_PUBLIC_API_URL + `/timesheets/${timesheet.id}`,
    postData,
    'PUT',
  )
  submitData
    .then(function (response) {
      alert(
        `Timesheet ${timesheet.attributes.name} has been approved successfully`,
      )
      // setTimeout(function () {
      //   router.push(`/timesheets/${timesheet.id}`);
      // }, 1000);
    })
    .catch(function (error) {
      if (error.response) {
        alert(error.response.data.message)
      } else if (error.request) {
        alert(
          'There seems to be an issue talking to our server. Please check your network connection and try again',
        )
      }
    })
}

export const sendIndividualTimesheetReminder = (
  userIDs: any[],
  date: string,
) => {
  const reminderPromises = []

  userIDs.map((user_id) => {
    const submitData = performRequest(
      process.env.NEXT_PUBLIC_API_URL +
        `/emails/reminders/timesheet_individual`,
      { user_id: user_id, date: date ? date : moment().format('YYYY-MM-DD') },
      'POST',
    )
    reminderPromises.push(submitData)
  })

  Promise.allSettled(reminderPromises).then(
    (results) => results.forEach((result) => console.log(result)), // Fire and forget but log to console for debugging
  )
}
