import { Box, Flex } from '@rebass/grid'
import * as Sentry from '@sentry/nextjs'
import {
  Alert,
  Link,
  Modal,
  Nav,
  SideNav,
  SideNavbar,
  SideNavItem,
  SideNavItemIcon,
  SideNavItemText,
  SideNavToggle,
  Toast,
} from '@tkxs/cast-ui'
import _ from 'lodash'
import { signOut, useSession } from 'next-auth/react'
import Head from 'next/head'
import Image from 'next/image'
import Script from 'next/script'
import React, { useEffect, useState } from 'react'
import { withBaseIcon } from 'react-icons-kit'
import { ic_alarm } from 'react-icons-kit/md/ic_alarm'
import { ic_card_travel } from 'react-icons-kit/md/ic_card_travel'
import { ic_dashboard } from 'react-icons-kit/md/ic_dashboard'
import { ic_domain } from 'react-icons-kit/md/ic_domain'
import { ic_format_list_numbered } from 'react-icons-kit/md/ic_format_list_numbered'
import { ic_people } from 'react-icons-kit/md/ic_people'
import { ic_people_alt_outline } from 'react-icons-kit/md/ic_people_alt_outline'
import { ic_supervised_user_circle_outline } from 'react-icons-kit/md/ic_supervised_user_circle_outline'
import { ic_local_police_outline } from 'react-icons-kit/md/ic_local_police_outline'
import styled from 'styled-components'
import { SWRConfig } from 'swr'
import {
  ActionButton,
  Divider,
  Loader,
  PrimaryNavbar,
  SessionUser,
  SSpinner,
} from '../common/common'
import { loadingGif } from '../common/constants'
import { useLocalStorage } from '../common/helpers'
import { fetcher, performRequest } from '../pages/api'
import { isAdmin } from '../utils/auth'
import RiskForm from './forms/Risk'
import WorklogForm from './forms/Worklog'
import UserCard from './UserCard'

interface Props {
  app?: any
  Component: React.Component | any
  pageProps: any
  router: any
  theme?: any
}

const SideIconContainer = withBaseIcon({
  size: 24,
  style: { color: '#aa1e2b' },
})

const SSideNavItem = styled(SideNavItem)`
  background: #aa1e2b !important;
  cursor: unset !important;
  &:hover,
  &:focus {
    background: #aa1e2b !important;
    cursor: unset !important;
  }
  .cui-sidenav-item-text {
    padding: 3px 0 0px 12px !important;
  }
`

export default function AppWrapper({ Component, pageProps, router }: Props) {
  const [showSideNav, setShowSideNav] = useLocalStorage('showSideNav', true)
  const [pageTitle, setPageTitle] = useState('Project HumVee')
  const [globalError, setGlobalError] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [workLogModalOpen, setWorkLogModalOpen] = useState(false)
  const [riskModalOpen, setRiskModalOpen] = useState(false)

  const swrOptions = {
    fetcher,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    onError: (error, key) => {
      if (typeof error.toJSON === 'function' && error.toJSON().status == 401) {
        Sentry.configureScope(function (scope) {
          scope.setLevel(Sentry.Severity.Debug)
          Sentry.captureMessage(error)
        })
        performRequest(process.env.NEXT_PUBLIC_API_URL + 'logout', {}, 'DELETE')
          .then(function (response) {
            signOut({
              callbackUrl: `/login`,
            })
          })
          .catch(function (error) {
            Sentry.configureScope(function (scope) {
              scope.setLevel(Sentry.Severity.Error)
              Sentry.captureMessage(error)
            })
            signOut({
              callbackUrl: `/login`,
            })
          })
      }
      // if (error && error.response && error.response.data && error.response.data.message) {
      //   setGlobalError(error.response.data.message);
      // }
    },
  }
  const { data: session, status } = useSession()

  const sideNavItemClickHandler = (e, route, []) => {
    router.push(route)
  }

  useEffect(() => {
    if (globalError.length > 5) {
      setTimeout(function () {
        setGlobalError('')
      }, 8000)
    }
  }, [globalError])

  useEffect(() => {
    if (process.env.NEXT_APP_ENV === 'development') {
      setShowAlert(true)
    }
    const titleFragments = _.compact(_.split(router.asPath, '/', 2))

    const title =
      titleFragments.length > 0
        ? _.startCase(titleFragments[0])
        : 'Project Humvee'
    const customTitle =
      document.querySelector('main nav h1')?.textContent || title
    setPageTitle(customTitle)
  }, [router])

  const { pageLoading } = pageProps

  if (!Component) return null

  const sessionUser: SessionUser = session && session?.user

  const pdfView = router.pathname.includes('/pdf')

  return status === 'loading' ? (
    <Loader className="-loading -active">
      <SSpinner className="spinner" size={30} />
    </Loader>
  ) : (
    <div>
      {session && status === 'authenticated' ? (
        <React.Fragment>
          <SWRConfig value={swrOptions}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <title>{`${pageTitle}` || `Project Humvee`}</title>
              <link rel="preconnect" href="https://fonts.googleapis.com"></link>
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin="anonymous"
              ></link>
              <link
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&IBM+Plex+Sans:wght@100;200;300;400;500;600;700&Libre+Franklin:wght@100;200;300;400;500;600;700;800;900&display=swap"
                rel="stylesheet"
              ></link>
            </Head>
            <div className={!pdfView && `app sideNav-${showSideNav}`}>
              {showAlert && !pdfView && (
                <Alert alertStyle="warning" lightMode>
                  This is a <b>TEST</b> environment. Go to{' '}
                  <a href="https://humvee.tezzasolutions.com/">
                    <u>https://humvee.tezzasolutions.com/</u>
                  </a>{' '}
                  if you want to update your actual tasks and timesheets.
                </Alert>
              )}
              {!pdfView && (
                <PrimaryNavbar>
                  <Nav left>
                    <span className="nav-logo">
                      <Image
                        placeholder="blur"
                        blurDataURL={loadingGif}
                        src="https://tezzasolutions.com/tbs2021/wp-content/uploads/2020/07/cropped-tiny-tbs-logo-236x75-1.png"
                        alt="Tezza"
                        width={237}
                        height={75}
                      />
                    </span>
                    <Link
                      href={`/organizations/${session.user['profile'].organizationId}`}
                    >
                      <h2 style={{ padding: '0 16px' }}>
                        {session.user['profile'].organization}
                      </h2>
                    </Link>
                    <Divider />
                    <ActionButton
                      btnStyle="primary"
                      btnSize="md"
                      onClick={(e) => {
                        setWorkLogModalOpen(true)
                      }}
                    >
                      <span>Add Work Log</span>
                    </ActionButton>
                    <Modal
                      isOpen={workLogModalOpen}
                      id="workLogModal"
                      modalTitle="Add Work Log"
                      onTitleClose={(e) => setWorkLogModalOpen(false)}
                      footerContent={null}
                    >
                      <WorklogForm
                        setWorkLogModalOpen={setWorkLogModalOpen}
                        employee_id={
                          sessionUser.profile.employeeId || sessionUser.id
                        }
                      />
                    </Modal>
                    <Divider />
                    <ActionButton
                      btnStyle="primary"
                      outline={true}
                      btnSize="md"
                      onClick={(e) => {
                        setRiskModalOpen(true)
                      }}
                    >
                      <span>Add Project Risk</span>
                    </ActionButton>
                    <Modal
                      isOpen={riskModalOpen}
                      id="workLogModal"
                      modalTitle="Add Project Risk"
                      onTitleClose={(e) => setRiskModalOpen(false)}
                      footerContent={null}
                    >
                      <RiskForm setRiskModalOpen={setRiskModalOpen} />
                    </Modal>
                  </Nav>
                  <Nav right>
                    {pageLoading && (
                      <div style={{ marginRight: '30px' }}>
                        <Loader className="-loading -active">
                          <SSpinner className="spinner" size={30} />
                        </Loader>
                      </div>
                    )}
                    <div style={{ marginRight: '15px' }}>
                      <Flex
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Box ml={3}>
                          <UserCard />
                        </Box>
                      </Flex>
                    </div>
                  </Nav>
                </PrimaryNavbar>
              )}
              <main>
                <Component {...pageProps} />
                <div id="pd2"></div>
                {process.env.NEXT_APP_ENV === 'production' && (
                  <>
                    <noscript>
                      <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                      />
                    </noscript>
                    <Script
                      strategy="afterInteractive"
                      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
                    />
                    <Script
                      id="google-analytics"
                      strategy="afterInteractive"
                      dangerouslySetInnerHTML={{
                        __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());   
                  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    user_id: '${sessionUser.id}',
                    groups: '${process.env.NEXT_PUBLIC_BASE_PATH}, ${process.env.NEXT_APP_ENV}'
                  });
                  `,
                      }}
                    />
                  </>
                )}
                <Script
                  id="page-title"
                  strategy="lazyOnload"
                  dangerouslySetInnerHTML={{
                    __html: `setTimeout(function(){document.title=document.querySelector('h1')?.textContent || document.querySelector('main nav h1')?.textContent || document.title},5000)`,
                  }}
                />
              </main>
            </div>
            {!pdfView && (
              <SideNavbar
                isOpen={showSideNav}
                background=""
                height=""
                secondaryNavbarBackground="#FFFFFF"
                onToggle={(e) => setShowSideNav(!showSideNav)}
                onSelect={sideNavItemClickHandler}
              >
                <SideNavToggle />
                <SideNav top>
                  <SideNavItem
                    path="/"
                    activeSideNavItem={router.pathname === '/'}
                  >
                    <SideNavItemIcon>
                      <SideIconContainer icon={ic_dashboard} />
                    </SideNavItemIcon>
                    <SideNavItemText>Dashboard</SideNavItemText>
                  </SideNavItem>
                  <SSideNavItem>
                    <SideNavItemText></SideNavItemText>
                  </SSideNavItem>
                  <SideNavItem
                    path="/projects"
                    activeSideNavItem={_.startsWith(
                      router.pathname,
                      '/projects',
                    )}
                  >
                    <SideNavItemIcon>
                      <SideIconContainer icon={ic_card_travel} />
                    </SideNavItemIcon>
                    <SideNavItemText>Projects</SideNavItemText>
                  </SideNavItem>
                  <SideNavItem
                    path="/tasks"
                    activeSideNavItem={_.startsWith(router.pathname, '/tasks')}
                  >
                    <SideNavItemIcon>
                      <SideIconContainer icon={ic_format_list_numbered} />
                    </SideNavItemIcon>
                    <SideNavItemText>Tasks</SideNavItemText>
                  </SideNavItem>
                  <SideNavItem
                    path="/timesheets"
                    activeSideNavItem={_.startsWith(
                      router.pathname,
                      '/timesheets',
                    )}
                  >
                    <SideNavItemIcon>
                      <SideIconContainer icon={ic_alarm} />
                    </SideNavItemIcon>
                    <SideNavItemText>Timesheets</SideNavItemText>
                  </SideNavItem>
                  <SSideNavItem>
                    <SideNavItemText></SideNavItemText>
                  </SSideNavItem>
                  <SideNavItem
                    path="/organizations"
                    activeSideNavItem={_.startsWith(
                      router.pathname,
                      '/organizations',
                    )}
                  >
                    <SideNavItemIcon>
                      <SideIconContainer icon={ic_domain} />
                    </SideNavItemIcon>
                    <SideNavItemText>Organizations</SideNavItemText>
                  </SideNavItem>
                  {isAdmin(session) && (
                    <SideNavItem
                      path="/teams"
                      activeSideNavItem={_.startsWith(
                        router.pathname,
                        '/teams',
                      )}
                    >
                      <SideNavItemIcon>
                        <SideIconContainer
                          icon={ic_supervised_user_circle_outline}
                        />
                      </SideNavItemIcon>
                      <SideNavItemText>Teams</SideNavItemText>
                    </SideNavItem>
                  )}
                  {(isAdmin(session) ||
                    (sessionUser.subordinates &&
                      sessionUser.subordinates.length > 0)) && (
                    <SideNavItem
                      path="/subordinates"
                      activeSideNavItem={_.startsWith(
                        router.pathname,
                        '/subordinates',
                      )}
                    >
                      <SideNavItemIcon>
                        <SideIconContainer icon={ic_people_alt_outline} />
                      </SideNavItemIcon>
                      <SideNavItemText>Subordinates</SideNavItemText>
                    </SideNavItem>
                  )}
                  <SSideNavItem>
                    <SideNavItemText></SideNavItemText>
                  </SSideNavItem>
                  {isAdmin(session) && (
                    <SideNavItem
                      path="/compliance"
                      activeSideNavItem={_.startsWith(
                        router.pathname,
                        '/compliance',
                      )}
                    >
                      <SideNavItemIcon>
                        <SideIconContainer icon={ic_local_police_outline} />
                      </SideNavItemIcon>
                      <SideNavItemText>Compliance</SideNavItemText>
                    </SideNavItem>
                  )}
                  {isAdmin(session) && (
                    <SideNavItem
                      path="/users"
                      activeSideNavItem={_.startsWith(
                        router.pathname,
                        '/users',
                      )}
                    >
                      <SideNavItemIcon>
                        <SideIconContainer icon={ic_people} />
                      </SideNavItemIcon>
                      <SideNavItemText>Users</SideNavItemText>
                    </SideNavItem>
                  )}
                </SideNav>
                {globalError.length > 5 && (
                  <Toast position="fixed bottom right" active>
                    {globalError}
                  </Toast>
                )}
              </SideNavbar>
            )}
          </SWRConfig>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Head>
            <title>{pageTitle || `Project Humvee`}</title>
            <link rel="preconnect" href="https://fonts.googleapis.com"></link>
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin="anonymous"
            ></link>
            <link
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&IBM+Plex+Sans:wght@100;200;300;400;500;600;700&Libre+Franklin:wght@100;200;300;400;500;600;700;800;900&display=swap"
              rel="stylesheet"
            ></link>
          </Head>
          <div className={'app auth'}>
            <Component {...pageProps} />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
